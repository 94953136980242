import React from 'react';
import {ElementsConsumer, CardElement} from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import {Link} from 'gatsby';
import validator from "validator";
import axios from "axios/index";
import {navigate} from "gatsby";
import {handleLogin} from "../services/auth";

class SignupForm extends React.Component {
    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        this.setState({
            [event.target.name + "Error"]: "",
        });
    }
    changeprice = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        this.setState({
            [event.target.name + "Error"]: "",
        });
        var id = event.target.value;
        const result = this.state.packages.filter(word => word.id === id);
        for (var v1 of this.state.packages) {
            if (parseInt(v1.id) === parseInt(id)) {
                this.setState({
                    amount: v1.price,
                })
            }
        }
    }
    printpackage = (home) => {
        if (home.price !== 0 && home.price !== "") {
            let price = home.price !== 0 ?
                ('£' + home.price + ' / ' + (home.limit > 1 ?
                    home.limit + ' ' + home.interval + 's' :
                    'a' + ' ' + home.interval)) :
                'Coming Soon';
            return (<option key={home.id} value={home.id}>{price}</option>)
        }
    }
    handleCheck = event => {
        this.setState({termscond: !this.state.termscond, termscondError: ''});
    }
    // handleCheck1 = event => {
    //     this.setState({membershipcheck: !this.state.membershipcheck, membershipcheckError: ''});
    // }
    addpromo = event => {
        if (this.state.amount !== '') {
            this.setState({showpromo: !this.state.showpromo, submitbtn: !this.state.submitbtn});
        } else {
            alert('Select Package please')
        }
    }
    validatePromo = (event) => {
        this.setState({
            errorspinner: true
        })
        axios.get(`${process.env.API_URL}/promo/findPromo/${this.state.promocode}`, {})
            .then((res) => {
                if (res.data.error === false) {
                    var amount = 0;
                    if (res.data.percentage === true) {
                        amount = this.state.amount - (this.state.amount * res.data.amount * 0.01)
                    } else {
                        amount = this.state.amount - res.data.amount
                    }
                    this.setState({
                        promofield: true, withpromo: true, withoutpromo: false, delamount: this.state.amount, amount: parseFloat(amount).toFixed(2), submitbtn: true
                    });
                    alert(`Congratulation! The discount code is now activated.`);
                }
                this.setState({
                    errorspinner: '', promores: res.data.error, promostatus: res.data.msg
                })
                setTimeout(() => {
                    this.setState({
                        errorspinner: '', promores: ''
                    })
                }, 5000)
            }).catch((err) => {
            console.log(err)
        })
    }
    state = {
        packages: [],
        fname: '',
        lname: '',
        price_id: '1',
        amount: '',
        termscond: false,
        membershipcheck: false,
        termscondError: '',
        membershipcheckError: '',
        username: '',
        email: '',
        password: '',
        cpassword: '',
        resSuccess: '',
        resError: '',
        emailError: '',
        success: '',
        promocode: '',
        passwordError: '',
        cpasswordError: '',
        address1Error: '',
        carderror: '',
        fnameError: '',
        lastError: '',
        errorspinner: '',
        postcodeError: '',
        cityError: '',
        mobile: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: '',
        showpromo: false,
        withoutpromo: true,
        delamount: '0.00',
        withpromo: false,
        promores: '',
        promofield: '',
        promostatus: false,
        submitbtn: true,
        promoMsg: '',
        promoMsgClass: '',
    }
    handleSubmit = async event => {
        event.preventDefault();
        if (this.state.termscond === false) {
            this.setState({
                termscondError: true
            });
            return false;
        } else {
            this.setState({
                termscondError: ""
            });
        }
        if (this.state.membershipcheck === false) {
            this.setState({
                membershipcheckError: true
            });
            return false;
        } else {
            this.setState({
                membershipcheckError: ""
            });
        }
        this.setState({
            errorspinner: 1
        });
        const {stripe, elements} = this.props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            this.setState({
                carderror: result.error.message
            });
        } else {
            this.setState({
                carderror: ''
            });
            var err = 0;
            const formState = this.state;
            try {
                var email = formState.email
                var username = email.split('@')[0];
                if (formState.price_id === "") {
                    alert("Select the package please")
                    err = 1;
                } else {
                    err = 0;
                }
                if (formState.fname === "") {
                    this.setState({
                        fnameError: 'First name is required'
                    })

                    err = 1;
                } else {
                    this.setState({
                        fnameError: ''
                    });
                    err = 0;
                }
                if (formState.lname === "") {
                    this.setState({
                        lastError: 'Last name is required'
                    })

                    err = 1;
                } else {
                    this.setState({
                        lastError: ''
                    });
                    err = 0;
                }
                if (!validator.isEmail(email)) {
                    this.setState({
                        emailError: 'Enter valid email address'
                    })
                    err = 1;
                } else {
                    this.setState({
                        emailError: ''
                    })
                    err = 0;
                }
                if (formState.password === "") {
                    this.state.PasswordError = 'Password is required';
                    err = 1;
                } else {
                    this.setState({
                        PasswordError: ''
                    })
                    err = 0;
                }
                if (formState.password !== formState.cpassword) {
                    this.state.cpasswordError = 'Password does not match';
                    err = 1;
                } else {
                    this.setState({
                        cpasswordError: ''
                    })
                    err = 0;
                }
                if (formState.address1 === "") {
                    this.state.address1Error = 'Address 1 field is required';
                    err = 1;
                } else {
                    this.setState({
                        address1Error: ''
                    })
                    err = 0;
                }
                if (formState.city === "") {
                    this.state.cityError = 'City field is required';
                    err = 1;
                } else {
                    this.setState({
                        cityError: ''
                    })
                    err = 0;
                }
                if (formState.postcode === "") {
                    this.state.postcodeError = 'Postcode field is required';
                    err = 1;
                } else {
                    this.setState({
                        postcodeError: ''
                    })
                    err = 0;
                }
                const emailuser = this.state.email;
                const passworduser = this.state.password;
                let couponMsg = document.getElementsByClassName('couponCodeMsg');

                if (err === 0) {
                    await axios.post(`${process.env.API_URL}/createuser1`, {
                        'fname': this.state.fname,
                        'lname': this.state.lname,
                        'username': username,
                        'price_id': this.state.price_id,
                        'email': this.state.email,
                        'mobile': this.state.mobile,
                        'address1': this.state.address1,
                        'address2': this.state.address2,
                        'city': this.state.city,
                        'county': this.state.county,
                        'postcode': this.state.postcode,
                        'amount': this.state.amount,
                        'password': this.state.password,
                        'token': result.token.id,
                        'promocode': this.state.promocode,
                        'blocked': true,
                        'confirmed': true,
                    }).then((res) => {
                        if (res.data.error === true) {
                            const client_secret = res.data.client_secret;
                            let current = this;
                            if (res.data.promo === false) {
                                stripe.confirmCardPayment(client_secret).then(function (result) {
                                    if (result.error) {
                                        alert('error');
                                        current.setState({
                                            resError: result.error.message
                                        });
                                        axios.post(`${process.env.API_URL}/deleteExistinguser`, {
                                            'id': res.data.userid,
                                        }).then((res) => {
                                            navigate('/signup')
                                        });
                                    } else { //asdasd
                                        current.setState({
                                            promoMsg: 'Sorry! Promo not applied.', promoMsgClass: 'alert alert-danger',
                                        });

                                        window.scrollTo({top: 0, behavior: 'smooth'});
                                        setTimeout(function () {
                                            handleLogin({email: emailuser, password: passworduser})
                                            navigate('/thank-you')
                                        }, 2000);
                                    }
                                });
                            } else if (res.data.promo === true) {
                                stripe.confirmCardSetup(client_secret).then(function (result) {
                                    if (result.error) {
                                        current.setState({
                                            resError: result.error.message
                                        })
                                        axios.post(`${process.env.API_URL}/deleteExistinguser`, {
                                            'id': res.data.userid,
                                        }).then((res) => {
                                            navigate('/signup')
                                        });
                                    } else {
                                        current.setState({
                                            promoMsg: 'Congratulation! Free 3 months code activated.', promoMsgClass: 'alert alert-success',
                                        });
                                        window.scrollTo({top: 0, behavior: 'smooth'});
                                        setTimeout(function () {
                                            handleLogin({email: emailuser, password: passworduser});
                                            navigate('/thank-you');
                                        }, 2000);
                                    }
                                });
                            }
                            this.setState({
                                errorspinner: ''
                            })
                            this.setState({
                                resError: res.data.msg
                            });
                            // Your card required 3d authentication, user other card please
                        } else {
                            this.setState({
                                resError: ""
                            });
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            setTimeout(function () {
                                handleLogin({email: emailuser, password: passworduser});
                                navigate('/thank-you');
                            }, 2000);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.setState({
                        errorspinner: ''
                    })
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    componentDidMount() {
        axios.get(`${process.env.API_URL}/packages`).then((res) => {
            this.setState({
                packages: res.data
            });
            this.setState({amount: res.data[0].price});
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (<div>
            {this.price_id}
            <form className="contactform signupform pl-0" onSubmit={this.handleSubmit}>
                <div className="offset-md-1 col-md-10">
                    {this.state.resError !== "" && <div className="alert alert-danger">{this.state.resError}</div>}
                    {this.state.resSuccess !== "" && <div className="alert alert-success">User created successfully</div>}
                    {this.state.promoMsg !== "" && <div className={this.state.promoMsgClass} id={'couponCodeMsg'}>{this.state.promoMsg}</div>}
                    <div className="row">
                        <div className="col-md-6  mb-1">
                            <label htmlFor="fname">First Name <span className="text-danger">*</span></label>
                            <input id="fname" className="form-control" name="fname" onChange={this.handleUpdate} type="text"/>
                            {this.state.fnameError !== "" && <span style={{fontWeight: 'bold', color: 'red'}}>{this.state.fnameError}</span>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lname">Last Name <span className="text-danger">*</span></label>
                            <input id="lname" name="lname" className="form-control" onChange={this.handleUpdate} type="text"/>
                            {this.state.lastError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.lastError}</span>}
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="email">Email<span className="text-danger">*</span></label>
                            <input id="email" name="email" className="form-control" onChange={this.handleUpdate} type="email"/>
                            {this.state.emailError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.emailError}</span>}
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="mobile">Mobile </label>
                            <input id="mobile" name="mobile" className="form-control noArrow" onChange={this.handleUpdate} type="number"/>
                        </div>
                        <div className="col-md-6 mb-1">
                            <label htmlFor="password">Password<span className="text-danger">*</span></label>
                            <input id="password" className="form-control " name="password" onChange={this.handleUpdate} type="password"/>
                            {this.state.PasswordError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.PasswordError}</span>}
                        </div>
                        <div className="col-md-6 mb-1">
                            <label htmlFor="cpassword">Confirm Password<span className="text-danger">*</span></label>
                            <input id="cpassword" className="form-control " name="cpassword" onChange={this.handleUpdate} type="password"/>
                            {this.state.cpasswordError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.cpasswordError}</span>}
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="address1">Address 1<span className="text-danger">*</span></label>
                            <input id="address1" name="address1" className="form-control" onChange={this.handleUpdate} type="text"/>
                            {this.state.address1Error !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.address1Error}</span>}
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="address2">Address 2</label>
                            <input id="address2" name="address2" className="form-control" onChange={this.handleUpdate} type="text"/>
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="city">City<span className="text-danger">*</span></label>
                            <input id="city" name="city" className="form-control" onChange={this.handleUpdate} type="text"/>
                            {this.state.cityError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.cityError}</span>}
                        </div>
                        <div className="col-md-6  mb-1">
                            <label htmlFor="county">County</label>
                            <input id="county" name="county" className="form-control" onChange={this.handleUpdate} type="text"/>
                        </div>
                        <div className="col-md-6  mb-1">
                            <div className="row">
                                <div className="col-md-12  mb-1">
                                    <label htmlFor="postcode">Postcode<span className="text-danger">*</span></label>
                                    <input id="postcode" name="postcode" className="form-control" onChange={this.handleUpdate} type="text"/>
                                    {this.state.postcodeError !== "" && <span style={{fontWeight: 'bold', color: 'red',}}>{this.state.postcodeError}</span>}
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>&nbsp;&nbsp;</label>
                                            <h4 className="font-600">
                                                Offer Code {!this.state.showpromo && <span onClick={this.addpromo} className="addpromo">Add Code</span>}
                                            </h4>
                                        </div>
                                        {this.state.showpromo && <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mb-1">
                                                    <label htmlFor="promocode" className="font-600">Promocode</label>
                                                    <input id="promocode" name="promocode"
                                                           className="form-control"
                                                           disabled={this.state.promofield === true && "disabled"}
                                                           onChange={this.handleUpdate}
                                                           type="text"/>
                                                </div>
                                                {this.state.promofield != true && <div className="col-md-6  mb-1">
                                                    <label className="d-block">&nbsp;&nbsp;</label>
                                                    <button type="button" className="btn btn-primary" onClick={this.validatePromo}> Verify
                                                        {this.state.errorspinner !== "" && <div className="spinner-border spinner-bordersignup" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>}
                                                    </button>
                                                    &nbsp;
                                                    <input type="button" className="btn btn-secondary" value="Cancel" onClick={this.addpromo}/>
                                                </div>}
                                                {this.state.promores === false && <div className="alert alert-success">{this.state.promostatus}</div>}
                                                {this.state.promores === true && <div className="alert alert-danger">{this.state.promostatus}</div>}
                                            </div>
                                        </div>}
                                        <div className="col-md-12 mt-3">
                                            <h6 className="font-600">Select Package</h6>
                                            <select className="form-control mb-1" name="price_id" id="price_id" defaultValue={1} onChange={this.changeprice}>
                                                <option disabled>Select</option>
                                                {this.state.packages.map(home => this.printpackage(home))}
                                            </select>
                                            {this.state.withoutpromo && <div className="totalamount">
                                                <h3>Total: <strong>&pound; {this.state.amount !== "" ? this.state.amount : '0.00'}</strong></h3>
                                            </div>}
                                            {this.state.withpromo && <div className="totalamount">
                                                <h3>Total:
                                                    <strong>
                                                        <del> &pound;{this.state.delamount !== "" ? this.state.delamount : '0.00'}</del>
                                                        &ensp;&pound;{this.state.amount}
                                                    </strong>
                                                </h3>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <CardSection/>
                                    {this.state.carderror && <div className="  card-error">{this.state.carderror}</div>}
                                </div>
                                <div className="form-group col-md-12 mt-3">
                                    <label className="checkbox-inline">
                                        <input type="checkbox" onChange={this.handleCheck} name="termscond" value="1"/> I confirm I have read and agree to the <Link to="/terms-and-condition">Terms
                                        & Conditions</Link>
                                    </label>
                                    {this.state.termscondError !== "" && <div className="mt-2" style={{fontWeight: 'bold', color: 'red',}}>Confirmation is required</div>}
                                </div>
                                {/*<div className="form-group col-md-12 mt-3">*/}
                                {/*    <label className="checkbox-inline">*/}
                                {/*        <input type="checkbox" onChange={this.handleCheck1} name="membershipcheck" value="1"/> I understand that my membership is an ongoing subscription service*/}
                                {/*        and will automatically renew on my expiry date. It can be cancelled anytime in accordance with the Terms and Conditions of Membership.*/}
                                {/*    </label>*/}
                                {/*    {this.state.membershipcheckError !== "" && <div className="mt-2" style={{fontWeight: 'bold', color: 'red',}}>Confirmation is required</div>}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-1">
                        {this.state.submitbtn && <button className="slide_from_left submitformbtn registerBuyNow col-md-5 col-sm-5 col-lg-3" disabled={!this.props.stripe} type="submit">
                                    <span className="btnicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.733" height="26.466" viewBox="0 0 19.733 26.466">
                                            <path id="Path_19" data-name="Path 19"
                                                  d="M252.74,164.675h-.548v-2.521a6.477,6.477,0,0,0-12.954,0v2.521h-.549a2.846,2.846,0,0,0-2.842,2.843V179.3a2.846,2.846,0,0,0,2.842,2.842h14.05a2.846,2.846,0,0,0,2.842-2.842V167.518a2.846,2.846,0,0,0-2.842-2.843m-5.517,9.387v1.387a1.508,1.508,0,0,1-3.017,0v-1.387a2.418,2.418,0,1,1,3.017,0m2.912-9.387H241.3v-2.521a4.42,4.42,0,0,1,8.84,0Z"
                                                  transform="translate(-235.849 -155.678)" fill="#fff"/>
                                        </svg>
                                    </span>
                            <span className="imagectabtnlable">Buy Now {this.state.errorspinner !== "" && <div className="spinner-border spinner-bordersignup" role="status"><span className="sr-only">Loading...</span></div>}
                                    </span>
                        </button>}
                    </div>
                </div>
            </form>
            {/*<p className="text-center font-600 mt-4">Already have account? <Link className="pinkcolor" to="/signup">Login Here</Link></p>*/}
        </div>);
    }
}

export default function InjectedSignupForm() {
    return (<ElementsConsumer>
        {({stripe, elements}) => (<SignupForm stripe={stripe} elements={elements}/>)}
    </ElementsConsumer>);
}
