import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import SignupForm from "../components/signup"
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import borderheadling from "../images/headlineborder.png";
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const stripePromise = loadStripe(`${process.env.STRIPE_PRODUCTION_KEY}`);

const SignupPage = () => {

    //TODO:: Redirect user to myaccount page if they are already logged in
    return (
        <main>
            <Header/>
            <AruzySeo title={"Signup"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'Register'}/>
                            </div>
                            <div className="row justify-content-center">
                                <Elements stripe={stripePromise}>
                                    <SignupForm/>
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default SignupPage